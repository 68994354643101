import React from "react";
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";

import "./Footer.scss";

const Footer = (props) => {
	return (
		<div className="footer">
			<div className="top_sec">
				<div className="content_container df acsa">
					<div className="df left_sec">
						<div className="list_wrpr">
							<h6 className="fs18_75 ffmsb ttuc">Menu</h6>

							<ul>
								<li className="df acsa ffmsb fs14_5">
									<Link to="/">Home</Link>
								</li>
								<li className="df acsa ffmsb fs14_5">
									<Link to="/about">About</Link>
								</li>
								<li className="df acsa ffmsb fs14_5">
									<Link to="/services">Services</Link>
								</li>
								<li className="df acsa ffmsb fs14_5">
									Projects
								</li>
								<li className="df acsa ffmsb fs14_5">
									<Link to="/contact">Contact</Link>
								</li>
							</ul>
						</div>

						<div className="list_wrpr">
							<h6 className="fs18_75 ffmsb ttuc">Service</h6>

							<ul>
								<li className="df acsa ffmsb fs14_5">
									<HashLink to="/services#eng">
										Engineering
									</HashLink>
								</li>
								<li className="df acsa ffmsb fs14_5">
									<HashLink to="/services#install">
										{" "}
										Installations
									</HashLink>
								</li>
								<li className="df acsa ffmsb fs14_5">
									<HashLink to="/services#inspect">
										Inspections
									</HashLink>
								</li>
								<li className="df acsa ffmsb fs14_5">
									<HashLink to="/services#inspect">
										Backflow Testing
									</HashLink>
								</li>
								<li className="df acsa ffmsb fs14_5">
									<HashLink to="/services#inspect">
										Repairs
									</HashLink>
								</li>
							</ul>
						</div>
					</div>

					<div className="mla right_sec">
						<div className="logo_wrpr"></div>

						<div className="address_wrpr">
							<p className="ffmb fs20">845-352-3601</p>

							<p className="ffmb fs20">
								office@ceasefiresprinkler.com
							</p>
							<p className="ffmb fs20">
								12 Dorset Rd, Spring Valley, NY 10977
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="bottom_sec">
				<div className="content_container df acsa">
					<p className="ffmr fs16_5">
						Copyright © 2019 Ceasefire Sprinkler Systems | Privacy
						Policy
					</p>
					<p className="ffmr fs16_5 mla">
						Designed By:{" "}
						<a rel="noopener noreferrer" target="_blank" href="http://omediadesign.com">
							Omedia
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Footer;
