import React from "react";

import "./About.scss";
import POM from "../pom/POM";

const About = (props) => {
	return (
		<>
			<div className="about">
				<div className="abt_landing_sec">
					<div className="content_container df acma ffc">
						<h2 className="ffmb fs42 tac">About CeaseFire</h2>
						<div className="mla wrpr">
							<div className="img_wrpr"></div>
							<div className="text_wrpr">
								<h3 className="ffmb fs18">
									A Message From Our Founder
								</h3>

								<p className="ffmr fs16_5">
									At CeaseFire, we strive to serve you with
									integrity, reliability, and professionalism.
									From small repairs to high-profile projects,
									we pride ourselves on delivering you nothing
									less than the best in fire protection and
									maintenance solutions.
								</p>
								<p className="ffmr fs16_5">
									We are honored that we get to play such a
									vital role in ensuring the safety and
									well-being of thousands of people.
								</p>

								<div className="sig"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="lws">
					<div className="content_container df acc ffc">
						<h4 className="ffmm fs42">For over 12 years</h4>
						<p className="ffmm fs16_5">
							CeaseFire has served hundreds of satisfied clients
							throughout New York and beyond.
						</p>

						<div className="devider"></div>

						<h5 className="ffmm fs20">
							Locations we are honored to have served
						</h5>

						<div className="df list_wrpr">
							<div className="df acsa ffmb fs16_5 location_wrpr">
								Rockland County
							</div>
							<div className="df acsa ffmb fs16_5 location_wrpr">
								Westchester
							</div>
							<div className="df acsa ffmb fs16_5 location_wrpr">
								Cortland
							</div>

							<div className="df acsa ffmb fs16_5 location_wrpr">
								New Rochelle
							</div>
							<div className="df acsa ffmb fs16_5 location_wrpr">
								Albany
							</div>
							<div className="df acsa ffmb fs16_5 location_wrpr">
								New Hampshire
							</div>

							<div className="df acsa ffmb fs16_5 location_wrpr">
								Orange County
							</div>
							<div className="df acsa ffmb fs16_5 location_wrpr">
								Amsterdam
							</div>
							<div className="df acsa ffmb fs16_5 location_wrpr">
								Port Jervis
							</div>
						</div>
					</div>
				</div>
				<div className="wcc">
					<div className="content_container df acsa ffc">
						<h4 className="ffmb fs40">
							Why our clients choose CeaseFire
						</h4>

						<div className="devider"></div>
						<div className="df acsa line">
							<div className="img pro"></div>
							<div className="conent_wrpr">
								<h5 className="ffmb fs33_5">
									Professionalism.
								</h5>
								<p className="ffmr fs16_5">
									Our experienced in-house engineers,
									designers and installers work
									<br /> together to complete your project
									efficiently.
								</p>
							</div>
						</div>
						<div className="df acsa line">
							<div className="img state_of_art"></div>
							<div className="conent_wrpr">
								<h5 className="ffmb fs33_5">
									State-of-the-art systems.{" "}
								</h5>
								<p className="ffmr fs16_5">
									We design a complete customized system
									unique to your project
									<br /> using the highest quality components.
								</p>
							</div>
						</div>
						<div className="df acsa line">
							<div className="img compliant"></div>
							<div className="conent_wrpr">
								<h5 className="ffmb fs33_5">100% Compliant.</h5>
								<p className="ffmr fs16_5">
									Our team ensures that every system installed
									conforms <br /> to local building code.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<POM></POM>
		</>
	);
};

export default About;
