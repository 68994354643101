import React from "react";
import Landing from "./landing/Landing";
import HowWeCanHelp from "./how-we-can-help/HowWeCanHelp";

import "./Home.scss";
import RecentProjects from "./recent-projects/RecentProjects";
import RoomSec from "./room-sec/RoomSec";
import POM from "../pom/POM";

const Home = (props) => {
	return (
		<div>
			<Landing></Landing>
			<HowWeCanHelp></HowWeCanHelp>
			<RecentProjects></RecentProjects>
			<RoomSec></RoomSec>
			<POM></POM>
		</div>
	);
};

export default Home;
