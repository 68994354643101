import React from "react";

import "./Services.scss";

import engIcon from "../../assets/eng-icon.png";
import insIcon from "../../assets/ins-icon.png";
import inspIcon from "../../assets/insp-icon.png";
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";

const ServicesPage = (props) => {
	return (
		<div className="services_page">
			<div className="banner">
				<div className="df ffc acc content_container">
					<h2 className="ffmb fs50 ttuc">How we can assist you</h2>
					<div className="df acsa secs_wrpr">
						<HashLink
							to="/services#eng"
							className="df acsa ffc sec_wrpr"
						>
							<div className="df acc ffc  icon_wrpr">
								<div
									className="icon"
									style={{
										backgroundImage: `url(${engIcon})`,
										height: "75px",
										width: "95px",
									}}
								></div>
							</div>
							<p className="ffmm fs25">Engineering</p>
						</HashLink>
						<HashLink
							to="/services#install"
							className="df acsa ffc sec_wrpr"
						>
							<div className="df acc ffc icon_wrpr">
								<div
									className="icon"
									style={{
										backgroundImage: `url(${insIcon})`,
										height: "85px",
										width: "98px",
									}}
								></div>
							</div>{" "}
							<p className="ffmm fs25">Installations</p>
						</HashLink>
						<HashLink
							to="/services#inspect"
							className="df acsa ffc sec_wrpr"
						>
							<div className="df acc ffc icon_wrpr">
								<div
									className="icon"
									style={{
										backgroundImage: `url(${inspIcon})`,
										height: "83px",
										width: "67px",
									}}
								></div>
							</div>{" "}
							<p className="ffmm fs25">Inspections</p>
						</HashLink>
					</div>

					<div className="scroll_arrow_wrpr">
						<div className="icon"></div>
					</div>
				</div>
			</div>

			<div className="sub_banner eng">
				<div className="dn anchor" id="eng"></div>
				<div className="df ffc acc content_container">
					<h4 className="ffmb fs64">ENGINEERING</h4>
					<p className="ffmr fs20 sub_text">
						Custom Systems for Every Project
					</p>
					<div class="devider"></div>
					<h5 className="ffmsb fs20 ttuc">
						When it comes to fire sprinklers, there is no “one size
						fits all” system.
					</h5>
				</div>
			</div>

			<div className="write_up_sec">
				<div className="df ffc acma content_container">
					<div className="bg_container"></div>
					<div className="bubble_container"></div>
					<p className="ffmsb fs16" style={{maxWidth: "1000px"}}>
						Hospitals, office buildings, housing developments, and
						care facilities require complex systems to ensure full
						coverage across multiple floors and throughout hundreds
						of rooms. Our engineers will precisely map out the most
						safe, yet cost-effective sprinkler placement for you.
					</p>
					<div className="devider"></div>
					<p className="ffmsb fs16" style={{maxWidth: "594px"}}>
						The expertise of our engineers will get your project
						approved efficiently by designing and submitting your
						backflow applications to get your water service
						connected in a timely manner.
					</p>
					<h5 className="ffmsb fs16">
						Contact us to get your your project started today.
					</h5>
					<Link
						to="/contact"
						className="df acsa ffmb fs15 contact_btn"
					>
						Contact Us
					</Link>
				</div>
			</div>

			<div className="sub_banner instltns">
				<div className="dn anchor" id="install"></div>
				<div className="df ffc acc content_container">
					<h4 className="ffmb fs64">INSTALLATIONS</h4>
					<p className="ffmr fs20 sub_text">
						For Commercial and Residential Properties
					</p>
					<div class="devider"></div>
					<h5 className="ffmsb fs20 ttuc">
						Our trained installers will complete your project to
						perfection
					</h5>
				</div>
			</div>

			<div className="instl_points">
				<div className="df ffc acsa content_container">
					<h3 className="ffmb fs25">
						Our team of in-house installers:
					</h3>

					<div className="list">
						<div className="ffmm fs17 df acsa list_line">
							Are qualified to handle installations in any type of
							property
						</div>
						<div className="ffmm fs17 df acsa list_line">
							Compliant with all local, state, and national rules
							and regulations regarding the installation of fire
							sprinkler systems
						</div>
						<div className="ffmm fs17 df acsa list_line">
							Install suitable fire sprinkler systems such as wet,
							dry, antifreeze, ESFR, deluge, preaction and fire
							pumps when applicable
						</div>
						<div className="ffmm fs17 df acsa list_line">
							Are fully insured for the safety of your project
						</div>
					</div>
					<div className="df ffc acc box">
						<h5 className="ffmsb fs25">Retrofitting?</h5>
						<p className="ffmr fs17 tac">
							If your fire sprinkler system is being retrofitted,
							your installer will work with you to find the best
							way to install the piping while minimizing
							disturbance to existing walls and ceiling molding.
						</p>
					</div>

					<h5 className="ffmsb fs17">
						Speak with one of our representatives to schedule your
						installation today
					</h5>

					<Link
						to="/contact"
						className="df acsa ffmb fs15 c_us_btn button"
					>
						Contact Us
					</Link>
				</div>
			</div>

			<div className="sub_banner inspctns">
				<div className="dn anchor" id="inspect"></div>
				<div className="df ffc acc content_container">
					<h4 className="ffmb fs64">INSPECTIONS</h4>
					<p className="ffmr fs20 sub_text">
						Ensure your existing system is in perfect working order
					</p>
					<div class="devider"></div>
					<h5 className="ffmsb fs20 ttuc">
						Regular Inspections Of Your System Are Vital
					</h5>
				</div>
			</div>

			<div className="inspct_points">
				<div className="df ffc acc content_container">
					<div className="inner_wrpr">
						<h3 className="ffmb fs25">Ceasefire Inspections:</h3>
						<div className="list">
							<div className="ffmm fs17 df acsa list_line">
								Detect potential issues before they threaten the
								safety and security of your family, staff, or
								residents
							</div>
							<div className="ffmm fs17 df acsa list_line">
								Safeguard your property from damage resulting
								from inadequate performance or system failure
							</div>
							<div className="ffmm fs17 df acsa list_line">
								Make sure your system meets local, state and
								national safety codes
							</div>
							<div className="ffmm fs17 df acsa list_line">
								Are imperative to passing mandatory fire safety
								inspections
							</div>
						</div>
						<p className="ffmm fs20 tac ">
							Schedule your inspection today
						</p>
						<div className="df acc">
							<Link
								to="/contact"
								className="df acsa ffmb fs15 c_us_btn button"
							>
								Contact Us
							</Link>
						</div>
					</div>
				</div>
			</div>

			{/* INSPECTIONS */}

			{/* Our team of in-house installers: */}

			<div className="sub_banner bottom">
				<div className="df acc content_container">
					<div>
						<p className="ffmb fs50">Backflow Prevention:</p>
						<p className="ffmm fs16_5">
							We are certified for backflow testing
						</p>
					</div>

					<Link to="/contact" className="ffmb fs14_5 df acc cu_btn">
						Contact our beckflow experts
					</Link>
				</div>
			</div>
		</div>
	);
};

export default ServicesPage;
