import React from "react";

import "./HowWeCanHelp.scss";

import engIcon from "../../../assets/eng-icon.png";
import insIcon from "../../../assets/ins-icon.png";
import inspIcon from "../../../assets/insp-icon.png";
import {HashLink} from "react-router-hash-link";

const HowWeCanHelp = (props) => (
	<div className="hwch_sec">
		<div className="content_container df acsa ffc">
			<h3 className="ffmb tac ttuc">How we can help you</h3>
			<div className="devider"></div>

			<div className="df sections_wrpr">
				<section className="df acsa ffc">
					<div className="df acc icon_wrpr">
						<div
							className="icon"
							style={{
								backgroundImage: `url(${engIcon})`,
								height: "75px",
								width: "95px",
							}}
						></div>
					</div>
					<h4 className="ffmr fs25">Engineering</h4>
					<p className="ffmr fs15_5 tac">
						Professionally-designed, custom-built fire sprinkler
						systems for your unique project
					</p>

					<HashLink
						to="services/#eng"
						className="ffmb fs13_5 ttuc button"
					>
						Learn more
					</HashLink>
					{/* <button className="ffmb fs13_5 ttuc">Learn more</button> */}
				</section>
				<section className="df acsa ffc">
					<div className=" df acc icon_wrpr">
						<div
							className="icon"
							style={{
								backgroundImage: `url(${insIcon})`,
								height: "85px",
								width: "98px",
							}}
						></div>
					</div>
					<h4 className="ffmr fs25">Installations</h4>
					<p className="ffmr fs15_5 tac">
						Get a new fire sprinkler system installed for your
						commercial or residential property
					</p>
					<HashLink
						to="services/#install"
						className="ffmb fs13_5 ttuc button"
					>
						Learn more
					</HashLink>
				</section>
				<section className="df acsa ffc">
					<div className=" df acc icon_wrpr">
						<div
							className="icon"
							style={{
								backgroundImage: `url(${inspIcon})`,
								height: "83px",
								width: "67px",
							}}
						></div>
					</div>
					<h4 className="ffmr fs25">Inspections</h4>
					<p className="ffmr fs15_5 tac">
						Make sure your fire sprinkler system is working
						perfectly and code compliant
					</p>
					<HashLink
						to="services/#inspect"
						className="ffmb fs13_5 ttuc button"
					>
						Learn more
					</HashLink>
				</section>
			</div>
		</div>
	</div>
);

export default HowWeCanHelp;
