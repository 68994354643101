import axios from "axios";
import React, {useState} from "react";
import "./Contact.scss";

const Contact = (props) => {
	const [location, setLocation] = useState("");
	const [name, setName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [ci, setCI] = useState(false);
	const [ri, setRI] = useState(false);
	const [inspection, setInspection] = useState(false);
	const [bt, setBT] = useState(false);
	const [repair, setRepair] = useState(false);

	const checkDisabled = () => {
console.log(email,phoneNumber,name,location)
		return !location.length ||
			!name.length ||
			!phoneNumber.length >= 7 ||
			!email.length
			? true
			: false;
	};

	const submitContact = async () => {
console.log("trying to submit") 
		let services = `${ci ? "Commercial installations, " : ""}${
			ri ? "Residential or private installations, " : ""
		}${inspection ? "Inspections, " : ""}${bt ? "Backflow Testing, " : ""}${
			repair ? "Repairs to existing systems" : ""
		}`;

console.log(services) 

		//
		//
		//
		//
		//
		await axios
			.post(
				"https://emailsenderprod.azurewebsites.net/api/emailSenderProduction",
				{
					fromEmail: "admin@shmili.com",
					//toEmail: "shmilibreuer@gmail.com",
				        toEmail: "office@ceasefiresprinkler.com",
					subject: "Email contact from the website",
					data: [
						{label: "Name", value: name},
						{label: "Email", value: email},
						{label: "Location", value: location},
						{label: "Phone Number", value: phoneNumber},
						{label: "Services Interested", value: services},
					],
				}
			)
			.catch((e) => e);

setLocation("");
setName("");
	setPhoneNumber("");
	setEmail("");
	setCI(false);
	setRI(false);
	 setInspection(false);
	setBT(false);
	setRepair(false);
services = "" ;
	};

	return (
		<div className="contact">
			<div className="banner">
				<div className="content_container df acc ffc">
					<h2 className="ffmb fs50">Let’s discuss your project</h2>
					<p className="ffmr fs16_5 tac">
						Whether you’d prefer a conference call, an in-office
						meeting, or an on-site visit, we’re
						<br /> more than happy to advise you, however best fits
						your schedule.
					</p>
				</div>
			</div>
			<div className="form_sec">
				<div className="content_container df acc ffc">
					<div className="df acsa header">
						<div className="df acsa sec">
							<div className="df acc icon_wrpr phone"></div>
							<div className="content">
								<div className="ffmr fs16_5 lbl">
									Call our office now on
								</div>
								<div className="ffmb fs20 val">
									845-352-3601
								</div>
							</div>
						</div>
						<div className="df acsa sec">
							<div className="df acc icon_wrpr email"></div>
							<div className="content">
								<div className="ffmr fs16_5 lbl">
									Send us an email introducing yourself to
								</div>
								<div className="ffmb fs20 val">
									office@ceasefiresprinkler.com
								</div>
							</div>
						</div>
					</div>

					<div className="form_wrpr">
						<p className="ffmm fs16_5 hdr">
							Fill in the short form, click “submit,” and we’ll
							get back to you within 24 hours
						</p>

						<div className="df form">
							<div className="left_sec">
								<div className="inpt_wrpr">
									<label className="ffmm fs16_5">
										Where are you located?
									</label>
									<input
										value={location}
										onChange={(e) =>
											setLocation(e.target.value)
										}
										type="text"
									></input>
								</div>

								<div className="inpt_wrpr">
									<label className="ffmm fs16_5">
										Your Name?
									</label>
									<input
										value={name}
										onChange={(e) =>
											setName(e.target.value)
										}
										type="text"
									></input>
								</div>

								<div className="inpt_wrpr">
									<label className="ffmm fs16_5">
										Your Phone Number?
									</label>
									<input
										value={phoneNumber}
										onChange={(e) =>
											setPhoneNumber(e.target.value)
										}
										type="text"
									></input>
								</div>
							</div>

							<div className="right_sec">
								<div className="inpt_wrpr">
									<label className="ffmm fs16_5">
										Your Email Address?
									</label>
									<input
										value={email}
										onChange={(e) =>
											setEmail(e.target.value)
										}
										type="text"
									></input>
								</div>
								<div className="checkboxes_wrpr">
									<label className="ffmm fs16_5">
										What services are you interested in?
									</label>
									<div className="df acsa ffmsb fs16_5 chcbx_line">
										<input
											checked={ci}
											onChange={(e) =>
												setCI(e.target.checked)
											}
											type="checkbox"
											name="selection"
											id="ci"
										></input>
										<label className="df acsa" for="ci">
											Commercial installations
										</label>
									</div>
									<div className="df acsa ffmsb fs16_5 chcbx_line">
										<input
											checked={ri}
											onChange={(e) =>
												setRI(e.target.checked)
											}
											type="checkbox"
											name="selection"
											id="rp"
										></input>
										<label className="df acsa" for="rp">
											Residential or private installations
										</label>
									</div>
									<div className="df acsa ffmsb fs16_5 chcbx_line">
										<input
											checked={inspection}
											onChange={(e) =>
												setInspection(e.target.checked)
											}
											type="checkbox"
											name="selection"
											id="insp"
										></input>
										<label className="df acsa" for="insp">
											Inspections
										</label>
									</div>

									<div className="df acsa ffmsb fs16_5 chcbx_line">
										<input
											checked={bt}
											onChange={(e) =>
												setBT(e.target.checked)
											}
											type="checkbox"
											name="selection"
											id="bckflow"
										></input>
										<label
											className="df acsa"
											for="bckflow"
										>
											Backflow Testing
										</label>
									</div>

									<div className="df acsa ffmsb fs16_5 chcbx_line">
										<input
											checked={repair}
											onChange={(e) =>
												setRepair(e.target.checked)
											}
											type="checkbox"
											name="selection"
											id="repair"
										></input>
										<label className="df acsa" for="repair">
											Repairs to existing systems
										</label>
									</div>
								</div>
							</div>
						</div>

						<div className="tac btn_wrpr">
							<button
								onClick={() => submitContact()}
								disabled={checkDisabled()}
								className="ffmsb s16_5 sbmt_btn test"
							>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="map_sec"></div>
		</div>
	);
};

export default Contact;

// Let’s discuss your project
