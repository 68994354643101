import React from "react";
import {Link} from "react-router-dom";

import "./POM.scss";

const POM = (props) => {
	return (
		<div className="pom">
			<div className="content_container df acc ffc">
				<h4 className="ffmb fs40">Get true peace of mind</h4>
				<p className="ffmm fs16_5 tac">
					Find out how you can shield your most precious assets - your
					family,
					<br />
					staff, and residents with a CeaseFire sprinkler system
				</p>

				<Link to="/contact" className="df acsa ffmb fs15 raq">
					Request a free Quote
				</Link>
			</div>
		</div>
	);
};

export default POM;
