import React from "react";
import {Link, NavLink} from "react-router-dom";

import "./Header.scss";

const Header = (props) => (
	<div className="main_hdr_wrpr">
		<div className="content_container df acsa">
			<div className="df acsa main_hdr">
				<Link to="/" className="logo_wrpr">
					<div className="logo"></div>
				</Link>
				<div className="mla df acca tbc navs_wrpr">
					<NavLink
						exact
						activeClassName="selected"
						className="df acsa ffmm ttuc fs13_5 nav"
						to="/"
					>
						Home
					</NavLink>

					<NavLink
						activeClassName="selected"
						className="df acsa ffmm ttuc fs13_5 nav"
						to="/about"
					>
						about
					</NavLink>

					<NavLink
						activeClassName="selected"
						className="df acsa ffmm ttuc fs13_5 nav"
						to="/services"
					>
						Services{" "}
					</NavLink>

					{/* <NavLink
            activeClassName="selected"
            className="df acsa ffmm ttuc fs13_5 nav"
            to="/projects"
          >
            Projects
          </NavLink> */}

					<NavLink
						activeClassName="selected"
						className="df acsa ffmm ttuc fs13_5 nav"
						to="/contact"
					>
						Contact us
					</NavLink>
					{/* </div> */}
				</div>
			</div>
		</div>
	</div>
);

export default Header;
