import React, {Component, createRef} from "react";

import "./Landing.scss";
import {Link} from "react-router-dom";

class Landing extends Component {
	constructor(props) {
		super(props);
		this.anchorRef = createRef();
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({truckPosition: true});
		}, 0);
	}

	state = {truckPosition: false};
	render() {
		return (
			<div className="landing_sec">
				<div className="content_container df acsa">
					<div className="left_wrpr">
						<h2 className="ffmb ttuc">
							Saving lives.
							<br /> saving homes
						</h2>
						<h4 className="fs16_5 ffmr">
							Protect what matters most with CeaseFire’s <br />{" "}
							state-of-the-art sprinkler systems.
						</h4>

						<div className="df btns_wrpr">
							<Link
								to="/contact"
								className="df acsa ffmb fs14_5 btn rq_btn"
							>
								Request A Free Quote
							</Link>

							<Link
								to="/services"
								className="df acsa ffmb fs14_5 btn lm_btn"
							>
								Learn more
							</Link>
						</div>
					</div>
					<div
						className="vans_bg mla"
						style={{
							marginRight: `${
								this.state.truckPosition ? "-88px" : "-700px"
							}`,
							opacity: `${
								this.state.truckPosition ? "1" : "0.6"
							}`,
						}}
					></div>

					<div className="logo_bg"></div>
				</div>
			</div>
		);
	}
}

export default Landing;
