import React from "react";

import "./RecentProjects.scss";

const RecentProjects = (props) => {
	return (
		<div className="recent_projects">
			<div className="content_container df acc ffc">
				<h3 className="ffmb fs33_5 ttuc">
					Some of our recent projects
				</h3>
				<div className="df cards_wrpr">
					<div className="card_wrpr">
						<p className="df acsa ffmb fs12_5 ttuc card_header">
							<span className="lbl">Location: </span>
							<span className="val">New Rochelle, NY</span>
						</p>
						<div className="img"></div>

						<div className="df acsa quote_wrpr">
							<div className="df acc logo_wrpr"></div>
							<div className="ffmr fs12_5 quote">
								“Our fire system hadn’t been serviced in a long
								time, and we were sure it would need replacing.
								CeaseFire thoroughly checked our system and
								informed us of the (minor) repairs needed in
								order to bring it into line with current
								regulations. We were thrilled with their
								transparency and professionalism.”
								{/* <div className="df acsa fs12 sig_wrpr">
                  <div className="ffhemmet fs23 sig">A. Sampleperson</div>{" "}
                  <span className="title"> - Owner</span>
                </div> */}
							</div>
						</div>
					</div>
					<div className="card_wrpr">
						<p className="df acsa ffmb fs12_5 ttuc card_header">
							<span className="lbl">Location: </span>
							<span className="val">Spring Valley, NY</span>
						</p>
						<div className="img"></div>

						<div className="df acsa quote_wrpr">
							<div className="df acc logo_wrpr"></div>
							<div className="ffmr fs12_5 quote">
								“I was impressed by Ceasefire from start to
								finish. They arrived on time, every time, and
								all work was completed to very high standards. I
								now recommend CeaseFire to anyone who needs fire
								sprinkler installation.”
								{/* <div className="df acsa fs12 sig_wrpr">
                  <div className="ffhemmet fs23 sig">A. Sampleperson</div>{" "}
                  <span className="title"> - Owner</span>
                </div> */}
							</div>
						</div>
					</div>
				</div>
				{/* <button className="df acsa ffmb fs15 take_a_look">
					Take a closer look
				</button> */}
			</div>
		</div>
	);
};

export default RecentProjects;
