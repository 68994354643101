import React from "react";
import {Link} from "react-router-dom";

import "./RoomSec.scss";

const RoomSec = (props) => {
	return (
		<div className="room_sec">
			<div className="df acsa ils">
				<div className="left_sec"></div>

				<div className="right_sec"></div>
			</div>
			<div className="content_container df acsa">
				<div className="left_sec">
					<h3 className="ffmb fs70">
						Because life <br /> is precious.
					</h3>

					<p className="ffmm fs20">
						This is our driving force at CeaseFire. It's why we
						always deliver the best and safest solutions available.
						No exceptions.
					</p>
					<Link to="/about" className="ffmb fs13_5 ttuc button">
						About ceasefire
					</Link>
				</div>
				<div className="right_sec"></div>
			</div>
		</div>
	);
};

export default RoomSec;
