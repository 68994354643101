import React from "react";
import "./App.scss";
import Home from "./components/home/Home";
import Header from "./components/header/Header";
import {BrowserRouter, Route} from "react-router-dom";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import ServicesPage from "./components/services/Services";
import Footer from "./components/footer/Footer";
import ScrollIntoView from "./ScrollIntoView";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<ScrollIntoView>
					<Header></Header>
					<Route exact path="/about" component={About}></Route>
					<Route exact path="/contact" component={Contact}></Route>
					<Route
						exact
						path="/services"
						component={ServicesPage}
					></Route>
					<Route exact path="/" component={Home}></Route>

					<Footer></Footer>
				</ScrollIntoView>
			</BrowserRouter>
		</div>
	);
}

export default App;
